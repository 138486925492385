import { Injectable } from '@angular/core';
import {
  AttemptsListRequestParams,
  AttemptsService,
  CertificatesService,
  CheckTaskStatusService,
  CompaniesService,
  CreateQuestionRequest,
  CreateUpdateTrainingRequest,
  GenerateQuizRequest,
  GroupsService,
  JobCategoriesService,
  LinkedEntitiesRequest,
  MaterialsListRequestParams,
  MaterialsService,
  PatchedPublicTrainingRequest,
  PatchedUpdateQuestionRequest,
  PrivateQuestion,
  TrainingsGroupReportListRequestParams,
  TrainingsListRequestParams,
  TrainingsParticipantsListRequestParams,
  TrainingsParticipantsReportListRequestParams,
  UploadFileRequest,
  UsersListRequestParams,
  UsersService,
} from '@cat-ai-us-fe/api';
import { TrainingsService as TrainingsApiService } from '@cat-ai-us-fe/api';
import { NotificationService } from '@cat-ai-us-fe/shared/data-access';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import {
  Observable,
  filter,
  forkJoin,
  map,
  repeat,
  switchMap,
  take,
  tap,
} from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TrainingsService {
  constructor(
    private userService: UsersService,
    private apiService: TrainingsApiService,
    private jobCategoriesService: JobCategoriesService,
    private departmentsService: GroupsService,
    private materialsService: MaterialsService,
    private attemptsService: AttemptsService,
    private certificateService: CertificatesService,
    private companyService: CompaniesService,
    private notificationsService: NotificationService,
    private statusService: CheckTaskStatusService,
  ) {}

  getTrainingNotifications() {
    return this.notificationsService.getNotifications({ kind: 'training' });
  }

  createTraining(createUpdateTrainingRequest: CreateUpdateTrainingRequest) {
    return this.apiService.trainingsCreate({
      createUpdateTrainingRequest,
    });
  }

  createQuizAttempt(id: number) {
    return this.apiService.trainingsStartUpdate({ id });
  }

  completeTraining(id: number) {
    return this.apiService.trainingsCompleteUpdate({ id });
  }

  getMaterials(params: MaterialsListRequestParams = {}) {
    return this.materialsService
      .materialsList(params)
      .pipe(map((r) => r.results));
  }

  getTrainings(params: TrainingsListRequestParams = {}) {
    return this.apiService
      .trainingsList({ ...params })
      .pipe(map((r) => r.results));
  }

  getTraining(id: number) {
    return this.apiService.trainingsRetrieve({
      id,
    }) as unknown as Observable<SupervisorTraining>;
  }

  getCertificate(uuid: string) {
    return this.certificateService.certificatesRetrieve({
      uuid,
    });
  }

  getAttempts(params: AttemptsListRequestParams) {
    return this.attemptsService.attemptsList(params);
  }

  getAttempt(id: number) {
    return this.attemptsService.attemptsRetrieve({
      id,
    });
  }

  getParticipantsCsv(trainingId: number) {
    return this.apiService.trainingsParticipantsCsvList({ trainingId });
  }

  getParticipants(params: TrainingsParticipantsListRequestParams) {
    return this.apiService.trainingsParticipantsList(params);
  }

  deleteTraining(id: number) {
    return this.apiService.trainingsDestroy({ id });
  }

  getUsers(filters: UsersListRequestParams = {}) {
    return this.userService.usersList(filters);
  }

  getUser(id: number) {
    return this.userService.usersRetrieve({ id });
  }

  getJobCategories() {
    return this.jobCategoriesService
      .jobCategoriesList({})
      .pipe(map((r) => r.results));
  }

  getDepartment(id: number) {
    return this.departmentsService.groupsRetrieve({ id });
  }

  getDepartments() {
    return this.departmentsService.groupsList({}).pipe(map((r) => r.results));
  }

  getGroupsReport(params: TrainingsGroupReportListRequestParams) {
    return this.apiService.trainingsGroupReportList(params);
  }

  getParticipantsReport(params: TrainingsParticipantsReportListRequestParams) {
    return this.apiService.trainingsParticipantsReportList(params);
  }

  getParticipantReport(trainingId: number, userId: number) {
    return this.apiService.trainingsParticipantsReportRetrieve({
      trainingId,
      userId,
    });
  }

  getCompany(id: number) {
    return this.companyService.companiesRetrieve({ id });
  }

  getCompanyReport() {
    return this.apiService.trainingsCompanyReportRetrieve();
  }

  getUserReport() {
    return this.apiService.trainingsUserReportRetrieve();
  }

  generateQuestions(payload: GenerateQuizRequest) {
    return this.apiService
      .trainingsGenerateQuizUpdate({
        generateQuizRequest: payload,
      })
      .pipe(
        map((res) => res.task_id),
        filter((task_id): task_id is string => !!task_id),
        switchMap((task_id) => this.checkGeneratedDocumentStatus(task_id)),
      );
  }

  private checkGeneratedDocumentStatus(taskId: string) {
    return this.statusService.checkTaskStatusRetrieve({ taskId }).pipe(
      repeat({ delay: 1000 }),
      tap((result) => {
        if (result.status == 'failure') {
          throw new Error(
            'Document generation is failed. Please, contact it support',
          );
        }
      }),
      filter((response) => response.status == 'success'),
      take(1),
      map((r) => r.result as PrivateQuestion[]),
    );
  }

  assignTrainingParticipants(
    trainingId: number,
    linkedEntitiesRequest: LinkedEntitiesRequest,
  ) {
    return this.apiService.trainingsParticipantsAssignUsersUpdate({
      trainingId,
      linkedEntitiesRequest,
    });
  }

  removeTrainingParticipants(
    trainingId: any,
    linkedEntitiesRequest: LinkedEntitiesRequest,
  ) {
    return this.apiService.trainingsParticipantsUnassignUsersUpdate({
      trainingId,
      linkedEntitiesRequest,
    });
  }

  updateTraining(
    id: number,
    patchedPublicTrainingRequest: PatchedPublicTrainingRequest,
  ) {
    return this.apiService.trainingsPartialUpdate({
      id,
      patchedPublicTrainingRequest,
    });
  }

  addQuestion(
    trainingId: number,
    createQuestionRequest: CreateQuestionRequest,
  ) {
    return this.apiService.trainingsQuestionsCreate({
      trainingId,
      createQuestionRequest,
    });
  }

  changeQuestion(
    trainingId: number,
    id: number,
    patchedUpdateQuestionRequest: PatchedUpdateQuestionRequest,
  ) {
    return this.apiService.trainingsQuestionsPartialUpdate({
      id,
      trainingId,
      patchedUpdateQuestionRequest,
    });
  }

  updateQuestionAnswer(
    id: number,
    trainingId: number,
    linkedEntitiesRequest: LinkedEntitiesRequest,
  ) {
    return this.apiService.trainingsQuestionsAnswerUpdate({
      id,
      trainingId,
      linkedEntitiesRequest,
    });
  }

  deleteQuestion(trainingId: number, id: number) {
    return this.apiService.trainingsQuestionsDestroy({ id, trainingId });
  }

  deleteAllQuestions(id: number) {
    return this.apiService.trainingsDeleteQuestionsDestroy({ id });
  }

  uploadMaterials(urls: string[]) {
    return forkJoin(
      urls.map((url) =>
        this.uploadMaterial({ url }).pipe(map((material) => material)),
      ),
    );
  }

  uploadMaterial(uploadFileRequest: UploadFileRequest) {
    return this.materialsService.materialsUploadFileCreate({
      uploadFileRequest,
    });
  }
}
