import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { PrivateAnswerVariant, PrivateQuestion } from '@cat-ai-us-fe/api';
import { MatIconModule } from '@angular/material/icon';
// TODO: Move to feature
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';

@Pipe({
  name: 'isSelectedPipe',
  standalone: true,
})
export class IsSelectedPipe implements PipeTransform {
  transform(variantId: number, selectedOptions: number[]): boolean {
    return selectedOptions.includes(variantId);
  }
}

@Component({
  selector: 'cat-ai-pass-quiz-question-form',
  standalone: true,
  imports: [MatIconModule, IsSelectedPipe],
  templateUrl: './pass-quiz-question-form.component.html',
})
export class PassQuizQuestionFormComponent implements OnInit {
  @Input() attemptId!: number | undefined;
  @Input() question!: PrivateQuestion;
  @Input() index!: number;
  @Input() uniqueId = '';
  @Output() setAnswer = new EventEmitter<number[]>();
  selectedOptions: number[] = [];
  destroyRef = inject(DestroyRef);

  constructor(private trainingService: TrainingsService) {}

  ngOnInit(): void {
    if (this.attemptId) {
      this.trainingService.getAttempt(this.attemptId).subscribe({
        next: (result) => {
          this.selectedOptions = result.answers
            .filter((answer) => answer.question.id === this.question.id)
            .map((answer) => answer.choice.id);
          this.setAnswer.emit(this.selectedOptions);
        },
        error: (error) => console.log(error),
      });
    }
  }

  chooseOption(variant: PrivateAnswerVariant) {
    const isSelected = this.selectedOptions.includes(variant.id);

    if (isSelected) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(variant.id), 1);
    } else {
      if (this.question.single) {
        this.selectedOptions[0] = variant.id;
      } else {
        this.selectedOptions.push(variant.id);
      }
    }
    // Update reference
    this.selectedOptions = this.selectedOptions.slice();

    this.setAnswer.emit(this.selectedOptions);
  }
}
