import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Attempt } from '@cat-ai-us-fe/api';
import { QuizNavigationComponent } from '../quiz-navigation/quiz-navigation.component';
import {
  LocalPrivateQuestion,
  QuestionsListComponent,
} from '../questions-list/questions-list.component';
import { DateFormatPipe, SecondsToTimePipe } from '@cat-ai-us-fe/shared/util';

@Component({
  selector: 'cat-ai-quiz-results-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    QuizNavigationComponent,
    QuestionsListComponent,
    DateFormatPipe,
    SecondsToTimePipe,
  ],
  templateUrl: './quiz-results-dialog.component.html',
})
export class QuizResultsDialogComponent implements OnInit {
  correntAnswers!: number | undefined;
  failedAnswers!: number | undefined;
  uniqueId = 'review-quiz';
  timeSpend!: number;
  StatusEnum = Attempt.StatusEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      attempt: Attempt;
      questions: LocalPrivateQuestion[] | undefined;
    },
  ) {}

  ngOnInit(): void {
    this.mapQuestion();
    this.setTimeSpent();
  }

  mapQuestion() {
    if (!this.data.attempt.answers) {
      return;
    }
    for (let q of this.data.questions!) {
      let answers = this.data.attempt.answers.filter((a) => {
        return (a.question as any).id === q.id;
      });

      if (!answers || !answers.length) {
        return;
      }

      q.variants = q.variants.map((v) => {
        const selectedAnswers = answers.filter(
          (answer) => answer.choice.id === v.id,
        );
        v.correctOption = selectedAnswers.some(
          (answer) => answer.choice.correct,
        );
        v.nonCorrectOption = selectedAnswers.some(
          (answer) => !answer.choice.correct,
        );
        return v;
      });
      q.hasCorrectAnswer = q.variants.some((v) => v.correctOption);
      q.hasUncorrentAnswer = q.variants.some((v) => v.nonCorrectOption);
    }

    this.correntAnswers = this.data.questions?.filter((q) =>
      q.variants.some((v) => v.correctOption),
    ).length;
    this.failedAnswers = this.data.questions?.filter((q) =>
      q.variants.some((v) => v.nonCorrectOption),
    ).length;
  }

  setTimeSpent() {
    if (!this.data.attempt.start_time || !this.data.attempt.end_time) {
      return;
    }
    const dateDifferenceInSeconds = (dateInitial: any, dateFinal: any) =>
      (dateFinal - dateInitial) / 1_000;

    this.timeSpend = dateDifferenceInSeconds(
      new Date(this.data.attempt.start_time),
      new Date(this.data.attempt.end_time),
    );
  }
}
