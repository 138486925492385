export const downloadCsvDocument = (data: Blob) => {
  const link = document.createElement('a');
  const file = new Blob([data], { type: 'type/csv' });
  let url = window.URL.createObjectURL(file);
  link.href = url;
  link.download = `${new Date().toLocaleDateString()}_participants.csv`;
  link.click();
};

export function printDocument(href: string) {
  window.open(href, '_blank', 'location=no');
}
