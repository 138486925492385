import {
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingFormComponent } from '@cat-ai-us-fe/trainings/ui';
import { CreateUpdateTrainingRequest, User } from '@cat-ai-us-fe/api';
import { AsyncPipe } from '@angular/common';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TrainingParticipantsComponent } from '../../components/training-participants/training-participants.component';
import { TrainingQuizComponent } from '../../components/training-quiz/training-quiz.component';
import { FORM_FIELDS, TRAINING_FORM_MODEL } from './config.const';
import {
  SupervisorTraining,
  TrainingFormType,
} from '@cat-ai-us-fe/trainings/util';
import { UploadAttachmentsService } from '@cat-ai-us-fe/shared/data-access';
import {
  EnumToArrayPipe,
  MatButtonLoadingDirective,
  getFileNameFromUrl,
  getTimeFromDate,
  setDateWithTime,
} from '@cat-ai-us-fe/shared/util';
import { MatTabsModule } from '@angular/material/tabs';
import { EMPTY, catchError, filter, map, mergeMap, of, tap } from 'rxjs';

enum StepperView {
  Details,
  Participants,
  Quiz,
}

@Component({
  selector: 'cat-ai-create-training-view',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    TrainingFormComponent,
    TrainingParticipantsComponent,
    TrainingQuizComponent,
    AsyncPipe,
    MatTabsModule,
    EnumToArrayPipe,
    MatButtonLoadingDirective,
  ],
  templateUrl: './build-training-view.component.html',
})
export class BuildTrainingViewComponent implements OnInit {
  @ViewChild(TrainingFormComponent) formComp!: TrainingFormComponent;
  StepperView = StepperView;
  selectedView = StepperView.Details;
  trainingModel: TrainingFormType = TRAINING_FORM_MODEL;
  formFields = FORM_FIELDS(this.trainingsService);
  private destroyRef = inject(DestroyRef);
  training!: SupervisorTraining;
  saving = false;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private trainingsService: TrainingsService,
    private uploadService: UploadAttachmentsService,
  ) {}

  ngOnInit(): void {
    this.trainingModel = { ...TRAINING_FORM_MODEL };
    this.subscribeToTraining();
  }

  goBack() {
    this.clearLocalStorage();
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  onTrainingFormChanged(data: TrainingFormType) {
    this.trainingModel = { ...this.trainingModel, ...data };
  }

  changeView(view: number) {
    if (view !== StepperView.Details) {
      if (!this.formComp?.validForm) {
        this.formComp?.markAsTouched();
        return;
      }
    }
    this.selectedView = view;
  }

  setParticipants(participants: User[]) {
    const value = participants.map((p) => p.id);
    this.trainingModel.participants = value;
  }

  setQuizData(quizData: Partial<SupervisorTraining>) {
    this.trainingModel.questions = quizData.questions;
    this.trainingModel.seconds_to_complete = quizData.seconds_to_complete;
  }

  onCreate(saveAsDraft = false) {
    if (!this.formComp?.validForm) {
      this.formComp?.markAsTouched();
      this.changeView(StepperView.Details);
      return;
    }
    this.saving = true;
    this.formComp.formGroup.disable();

    of(this.trainingModel.cover)
      .pipe(
        mergeMap((cover) =>
          cover && cover.length > 0 && (cover[0] as File).type
            ? this.uploadService.uploadAttachments(
                this.trainingModel.cover as unknown as File[],
              )
            : of([]),
        ),
        map((res) => this.mapModel(res && res[0], saveAsDraft)),
        mergeMap((mappedForm) =>
          this.training && this.training.id
            ? this.trainingsService.updateTraining(this.training.id, mappedForm)
            : this.trainingsService.createTraining(mappedForm),
        ),
        catchError(() => {
          return of();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res) => {
        this.saving = false;
        this.formComp.formGroup.enable();
        this.clearLocalStorage();
        if (res && res.id) {
          this.router.navigateByUrl(`trainings/${res.id}`);
        }
      });
  }

  private clearLocalStorage() {
    localStorage.removeItem('training-participants');
    localStorage.removeItem('training-quiz');
    localStorage.removeItem('training-form');
  }

  private mapModel(coverUrl: string, saveAsDraft: boolean) {
    this.trainingModel.coverUrl = coverUrl;
    if (saveAsDraft) {
      this.trainingModel.status = CreateUpdateTrainingRequest.StatusEnum.Draft;
    } else {
      this.trainingModel.status = CreateUpdateTrainingRequest.StatusEnum.Active;
    }
    return this.mapFormValue(this.trainingModel);
  }

  private subscribeToTraining() {
    this.loading = true;
    this.route.paramMap
      .pipe(
        map((params) => params.get('id')),
        tap((id) => {
          this.loading = !!id;
        }),

        mergeMap((id) => (id ? this.trainingsService.getTraining(+id) : EMPTY)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res) => {
        this.setTrainingModel(res);
        this.loading = false;
      });
  }

  private mapFormValue(form: TrainingFormType): CreateUpdateTrainingRequest {
    const {
      name,
      description,
      pass_score,
      materials,
      participants,
      questions,
      coverUrl,
      seconds_to_complete,
      status,
      certificate_valid_until,
    } = this.trainingModel;
    return {
      name,
      description,
      pass_score,
      materials: materials.map((m) => m.value || m),
      participants,
      questions: questions?.map((q) => {
        delete q.training;
        return q;
      }),
      seconds_to_complete,
      status,
      certificate_valid_until,
      end: setDateWithTime(form.end_date, form.end_time),
      start: setDateWithTime(form.start_date, form.start_time),
      cover: coverUrl,
    };
  }

  private setTrainingModel(training: SupervisorTraining) {
    this.training = training;
    const {
      name,
      description,
      pass_score,
      materials,
      questions,
      cover,
      seconds_to_complete,
      start,
      end,
      status,
      certificate_valid_until,
    } = training;
    this.trainingModel = {
      name,
      description,
      pass_score,
      certificate_valid_until,
      materials:
        materials?.map((m) => {
          return {
            label: m.material?.title || getFileNameFromUrl(m.file?.url) || '-',
            value: m.id,
          };
        }) || [],
      participants: [],
      questions,
      status,
      cover: cover ? ([{ name: cover }] as any) : undefined,
      seconds_to_complete,
      start_date: start,
      start_time: getTimeFromDate(start),
      end_date: training.end,
      end_time: getTimeFromDate(end),
    };
  }
}
