import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {
  MatButton,
  MatButtonModule,
  MatIconButton,
} from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { TrainingReportSnapshotComponent } from '@cat-ai-us-fe/trainings/ui';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { ActivatedRoute } from '@angular/router';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import {
  Observable,
  map,
  filter,
  switchMap,
  combineLatest,
  tap,
  of,
} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import {
  Attempt,
  Company,
  CompanyGroup,
  TrainingGroup,
  TrainingParticipants,
  User,
} from '@cat-ai-us-fe/api';
import { tepmlateToPdf } from '@cat-ai-us-fe/shared/util';

@Component({
  selector: 'cat-ai-report-document-view',
  standalone: true,
  imports: [
    MatIcon,
    MatButtonModule,
    MatTabsModule,
    TrainingReportSnapshotComponent,
    AsyncPipe,
  ],
  templateUrl: './report-document-view.component.html',
})
export class ReportDocumentViewComponent implements OnInit {
  currentTraining$!: Observable<SupervisorTraining>;
  participantReport$!: Observable<TrainingParticipants[]>;
  groupReport$!: Observable<TrainingGroup[]>;
  groupParticipantReport$!: Observable<TrainingParticipants[]>;
  attempt$!: Observable<Attempt | null>;
  group$!: Observable<CompanyGroup>;
  groupId$!: Observable<number>;
  participantId$!: Observable<string>;
  noParticipantId$!: Observable<string>;
  trainingId$!: Observable<number>;
  user$!: Observable<User>;
  company$!: Observable<Company>;
  loading = false;

  constructor(
    private apiService: TrainingsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setRouteData();
    this.setReports();
    this.setCurrentTraining();
    this.setGroup();
    this.setUser();
  }

  download(btn: MatButton | MatIconButton, printDocument = false) {
    btn.disabled = true;
    const template = document.getElementById('training_report');
    const filename = `Training_Report_${new Date().toLocaleDateString()}`;
    tepmlateToPdf(template, filename, btn, printDocument);
  }

  setLoadingState(loading: boolean) {
    this.loading = loading;
    this.cdr.detectChanges();
  }

  private setRouteData() {
    this.groupId$ = this.route.paramMap.pipe(
      map((params) => params.get('groupId')),
      filter((id): id is string => !!id),
      map((id) => +id),
    );
    this.participantId$ = this.route.paramMap.pipe(
      map((params) => params.get('memberId')),
      filter((id): id is string => !!id),
    );
    this.noParticipantId$ = this.route.paramMap.pipe(
      map((params) => params.get('memberId')),
      filter((id): id is string => !id),
    );
    this.trainingId$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
      filter((id): id is string => !!id),
      map((id) => +id),
    );
  }

  private setCurrentTraining() {
    this.currentTraining$ = this.trainingId$.pipe(
      switchMap((id) => this.apiService.getTraining(id)),
    );
  }

  private setReports() {
    this.participantReport$ = combineLatest([
      this.trainingId$,
      this.participantId$,
    ]).pipe(
      switchMap(([trainingId, userId]) =>
        this.apiService.getParticipantReport(trainingId as any, Number(userId)),
      ),
      map((res) => [res]),
      tap(
        () =>
          (this.attempt$ = this.trainingId$.pipe(
            switchMap((training) => this.apiService.getAttempts({ training })),
            map((res) => {
              const attempts = res.results;
              return attempts.length ? attempts[0] : null;
            }),
          )),
      ),
    );

    this.groupReport$ = combineLatest([
      this.trainingId$,
      this.groupId$,
      this.noParticipantId$,
    ]).pipe(
      switchMap(([id, groups]) =>
        this.apiService.getGroupsReport({ id, groups: [groups] }),
      ),
      map((res) => res.results),
      tap((res) => {
        const best_attempt = res.find((r) => !!r.best_attempt)?.best_attempt;
        this.attempt$ = of(best_attempt || null);
      }),
    );
    this.groupParticipantReport$ = combineLatest([
      this.trainingId$,
      this.groupId$,
      this.noParticipantId$,
    ]).pipe(
      switchMap(([id, groups]) =>
        this.apiService.getParticipantsReport({ id, groups: [groups] }),
      ),
      map((res) => res.results),
    );
  }

  private setGroup() {
    this.group$ = this.groupId$.pipe(
      switchMap((id) => this.apiService.getDepartment(id)),
      tap((res) => (this.company$ = this.apiService.getCompany(res.company))),
    );
  }

  private setUser() {
    this.user$ = this.participantId$.pipe(
      switchMap((id) => this.apiService.getUser(+id)),
    );
  }
}
