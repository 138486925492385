import { Component, DestroyRef, inject } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { CustomMaterialModalComponent } from '@cat-ai-us-fe/trainings/ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { EMPTY, switchMap } from 'rxjs';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { UploadAttachmentsService } from '@cat-ai-us-fe/shared/data-access';
import { MaterialLink } from '@cat-ai-us-fe/api';
import { getFileNameFromUrl } from '@cat-ai-us-fe/shared/util';
import { FormlySelectOption } from '@ngx-formly/core/select';

@Component({
  selector: 'cat-ai-training-material-selector',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatChipsModule],
  templateUrl: './training-material-selector.component.html',
})
export class TrainingMaterialSelectorComponent extends FieldWrapper {
  dialog = inject(MatDialog);
  apiService = inject(TrainingsService);
  uploadService = inject(UploadAttachmentsService);
  destroyRef = inject(DestroyRef);
  customMaterials: MaterialLink[] = [];

  addMaterial() {
    this.dialog
      .open(CustomMaterialModalComponent, {
        minWidth: '570px',
      })
      .afterClosed()
      .pipe(
        switchMap((files: File[]) => {
          if (files.length) {
            return this.uploadService
              .uploadAttachments(files)
              .pipe(switchMap((res) => this.apiService.uploadMaterials(res)));
          } else {
            return EMPTY;
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res: MaterialLink[]) => {
        if (!res) {
          return;
        }

        const value = this.formControl.value || [];
        const materials: FormlySelectOption[] = res.map((r) => {
          return {
            label:
              r.material.title || getFileNameFromUrl(r.file.url) || 'no_name',
            value: r.material && r.material.material_link,
          };
        });
        this.formControl.setValue([...value, ...materials]);
      });
  }

  remove(e: MouseEvent, index: number) {
    e.preventDefault();
    const value: number[] = this.formControl.value || [];
    const idToRemove = value.find(
      (el: number) => el === this.customMaterials[index].id,
    );
    if (idToRemove) {
      const indexOfMaterilaToRemove = value.indexOf(idToRemove);
      value.splice(indexOfMaterilaToRemove, 1);
    }
    this.customMaterials.splice(index, 1);
    this.formControl.setValue(value);
  }
}
