<div
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="scrollDown.emit()"
  class="{{
    horizontalView ? 'flex overflow-auto' : 'grid grid-cols-3 '
  }} gap-6 {{ wrapperClasses }}"
  [ngStyle]="{ 'max-width': horizontalView ? maxWidth + 'px' : 'unset' }"
>
  @for (training of data; track $index) {
    <a [routerLink]="['../', training.id]">
      <div
        class="w-full bg-white flex rounded-md {{
          horizontalView
            ? 'border border-gray-200 rounded-sm '
            : 'shadow-sm flex-col'
        }}"
      >
        <div
          class="rounded-md  bg-cover bg-gray-100 bg-center {{
            horizontalView ? 'my-4 ml-4 h-28 w-32 min-w-32' : 'h-36'
          }}"
          [style.background-image]="'url(' + training.cover + ')'"
        >
          @if (!horizontalView) {
            <ng-container
              *ngTemplateOutlet="
                statusBadge;
                context: { $implicit: training.status }
              "
            ></ng-container>
          }
        </div>
        <div class="flex-col flex gap-4 p-4">
          <span class="flex items-baseline">
            <h4
              class="whitespace-nowrap max-w-full overflow-hidden text-ellipsis"
            >
              {{ training.name }}
            </h4>
            @if (horizontalView) {
              <ng-container
                *ngTemplateOutlet="
                  statusBadge;
                  context: { $implicit: training.status }
                "
              ></ng-container>
            }
          </span>

          <p class="line-clamp-3 text-gray-700">
            {{ training.description }}
          </p>
          @if (!horizontalView) {
            <span class="flex gap-2.5 text-gray-600">
              <mat-icon class="text-base !w-4 !h-4 leading-none"
                >access_time</mat-icon
              >
              <p class="whitespace-nowrap leading-3 place-self-end">
                Due to {{ training.end | dateFormat: dateFormat }}
              </p>
            </span>
          }
        </div>
      </div>
    </a>
  } @empty {
    @if (!loading) {
      No trainings found
    }
  }
  @if (loading) {
    <div class="col-span-3 flex items-center justify-center">
      <mat-spinner [strokeWidth]="3" [diameter]="50"></mat-spinner>
    </div>
  }
</div>

<ng-template #statusBadge let-status>
  <div
    class="flex max-w-fit bg-cover rounded-large max-h-fit px-2.5 py-1.5  leading-none cat-badge-status-{{
      status
    }}"
    [class.m-4]="!horizontalView"
    [class.ml-4]="horizontalView"
  >
    {{ status }}
  </div>
</ng-template>
