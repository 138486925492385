import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {
  MatButton,
  MatButtonModule,
  MatIconButton,
} from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { CertificateSnapshotComponent } from '@cat-ai-us-fe/shared/ui';
import { Observable, filter, map, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { CertificatePublic, User } from '@cat-ai-us-fe/api';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { AsyncPipe } from '@angular/common';
import { tepmlateToPdf } from '@cat-ai-us-fe/shared/util';

@Component({
  selector: 'cat-ai-certificate-view',
  standalone: true,
  imports: [
    MatIcon,
    MatButtonModule,
    MatTabsModule,
    CertificateSnapshotComponent,
    AsyncPipe,
  ],
  templateUrl: './certificate-view.component.html',
})
export class CertificateViewComponent implements OnInit {
  loading = false;
  training$!: Observable<SupervisorTraining>;
  certificate$!: Observable<CertificatePublic>;
  user$!: Observable<User>;

  constructor(
    private apiService: TrainingsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  download(btn: MatButton | MatIconButton, printDocument = false) {
    btn.disabled = true;
    const template = document.getElementById('certificate_pdf');
    const filename = `Certicate_${new Date().toLocaleDateString()}`;
    tepmlateToPdf(template, filename, btn, printDocument);
  }

  setLoadingState(loading: boolean) {
    this.loading = loading;
    this.cdr.detectChanges();
  }

  private loadData() {
    this.certificate$ = this.route.paramMap.pipe(
      map((params) => params.get('certificateUuid')),
      filter((uuid): uuid is string => !!uuid),
      switchMap((uuid) => this.apiService.getCertificate(uuid)),
    );

    this.user$ = this.apiService.getUser('me' as any).pipe(map((res) => res));
    this.training$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
      filter((id): id is string => !!id),
      switchMap((id) => this.apiService.getTraining(+id)),
    );
  }
}
