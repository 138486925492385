import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { GenerateQuizModalComponent } from '../../modals/generate-quiz-modal/generate-quiz-modal.component';
import { CollapsibleCardComponent } from '@cat-ai-us-fe/shared/ui';
import { MatIconModule } from '@angular/material/icon';
import { PrivateQuestion } from '@cat-ai-us-fe/api';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { QuestionsListComponent } from '@cat-ai-us-fe/trainings/ui';
import {
  SupervisorTraining,
  TrainingFormType,
} from '@cat-ai-us-fe/trainings/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cat-ai-training-quiz',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    GenerateQuizModalComponent,
    CollapsibleCardComponent,
    QuestionsListComponent,
  ],
  templateUrl: './training-quiz.component.html',
})
export class TrainingQuizComponent implements OnChanges, OnInit {
  @Input() trainingDetails!: TrainingFormType;
  @Input() training!: SupervisorTraining;
  @Output() setQuiz = new EventEmitter<Partial<SupervisorTraining>>();
  questions!: PrivateQuestion[];
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);
  private apiService = inject(TrainingsService);

  ngOnInit() {
    const data = localStorage.getItem('training-quiz');
    if (!data) {
      return;
    }

    const parsedData = JSON.parse(data);
    this.questions = parsedData.questions;
    this.emitQuestions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.training && this.training) {
      this.questions = this.training.questions || [];
    }
  }

  create(questions?: PrivateQuestion[]) {
    this.dialog
      .open(GenerateQuizModalComponent, {
        minWidth: '1040px',
        minHeight: '640px',
        data: {
          trainingForm: this.trainingDetails,
          questions: questions,
          training: this.training,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Partial<SupervisorTraining>) => {
        if (res) {
          this.questions = res.questions || [];
          this.persistQuestions(res);
          this.emitQuestions(res);
        }
      });
  }

  edit($event: MouseEvent) {
    $event.stopPropagation();
    this.create(this.questions);
  }

  delete() {
    if (this.training && this.training.id) {
      this.apiService
        .deleteAllQuestions(this.training.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.questions = [];
          this.persistQuestions();
          this.emitQuestions();
        });
    } else {
      this.questions = [];
      this.persistQuestions();
      this.emitQuestions();
    }
  }

  private persistQuestions(partialTraining?: Partial<SupervisorTraining>) {
    localStorage.setItem(
      'training-quiz',
      JSON.stringify(partialTraining || { questions: this.questions }),
    );
  }

  private emitQuestions(partialTraining?: Partial<SupervisorTraining>) {
    this.setQuiz.emit(partialTraining || { questions: this.questions });
  }
}
